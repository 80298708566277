import { useEffect, useState } from "react";

import AdapterTypes from "./AdapterTypes";
import FirmwareDate from "./FirmwareDate";
import { PayloadValue, ProtocolId } from "./types";

import { CanAdaptersProps } from "../types";
import { FirmwareDateValue } from "../../../../../../../DatePicker/types";
import { INVALID_DATE, TASK_TYPES } from "../../../../../../../../shared";

const CanAdapters = ({ isLoading, type, changePayload }: CanAdaptersProps) => {
    const [payloadValue, setPayloadValue] = useState<PayloadValue>({
        protocol_id: null,
        firmware_date: null
    });

    const { protocol_id, firmware_date } = payloadValue;

    useEffect(() => {
        if (
            firmware_date !== INVALID_DATE &&
            (type === TASK_TYPES.TxCanAdapterUpdate
                ? true
                : protocol_id !== null)
        ) {
            const canAdaptersPayload = {
                firmware_date,
                ...(type !== TASK_TYPES.TxCanAdapterUpdate && {
                    protocol_id
                })
            };

            changePayload({
                id: "attributes",
                value: JSON.stringify(canAdaptersPayload)
            });
        } else {
            changePayload(null);
        }

        // eslint-disable-next-line
    }, [protocol_id, firmware_date, type]);

    const changePayloadValue = (value: ProtocolId | FirmwareDateValue) =>
        setPayloadValue({ ...payloadValue, ...value });

    return (
        <>
            {type !== TASK_TYPES.TxCanAdapterUpdate && (
                <AdapterTypes
                    taskType={type}
                    isSubmitLoading={isLoading}
                    change={changePayloadValue}
                />
            )}

            <FirmwareDate
                key={type}
                isLoading={isLoading}
                change={changePayloadValue}
            />
        </>
    );
};

export default CanAdapters;
